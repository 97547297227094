// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small tablets
$screen-lg-min: 992px;

// Large tablets and tablets
$screen-xl-min: 1200px;

@mixin bigMobile {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin desktop {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

@mixin animateEnter {
  animation: slideUp 0.7s ease-in-out forwards;
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
@mixin animateEnterCookie {
  animation: slideUp 1.4s ease-in-out forwards;
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
