@import "~antd/dist/antd.less";

.app {
  height: 100%;
  width: 100% !important;

  .ant-back-top {
    right: 40px !important;
    span {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background: #ffffffbd;
      box-shadow: 0px 5px 7px 0px #17030366;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;

      svg {
        transform: scale(1.4);
      }
    }
  }
}

iframe {
  width: 100% !important;
}

@primary-color: #1DA57A;