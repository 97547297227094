@import "../../styles/variables.scss";
@import "../../styles/breakpoints.scss";

* {
  box-sizing: border-box;
}

.header {
  display: flex;
  height: 140px;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  > img {
    @include tablet {
      height: 100px;
      width: 100px;
      margin-left: 15px;
      margin-right: 15px;
    }
    width: 55px;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 100%;
    box-shadow: $img-shadow;
  }

  > h1 {
    margin: 0;
    padding: 0;
    font-weight: bolder;
    font-size: 0.8rem;
    @include tablet {
      font-size: 1.8rem;
    }
  }

  > h3 {
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
  }
}
