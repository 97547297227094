.spin-logo {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 60px !important;
  height: 60px !important;
  margin: auto;
  opacity: 0.8;
}

.ant-spin {
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  width: 100px !important;
  height: 100px !important;
  margin: auto !important;
}

