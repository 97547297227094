@import "../../styles/breakpoints.scss";

.navbar {
  background: #ffffffba;
  position: sticky;
  z-index: 100;
  top: 0;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-content: flex-start;
  backdrop-filter: blur(12px);
  box-shadow: 0px 0px 20px 0px #e2e2e2;
  height: 140px;
  .ant-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    @include tablet {
      justify-content: space-around;
      padding: 0 100px;
    }
    .ant-menu-item-icon {
      font-size: 15px;
      font-weight: 600;
      @include tablet {
        font-size: 20px;
      }
    }

    .ant-menu-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      font-size: 15px;
      font-weight: 600;
      border: none !important;
    }
    .anticon-menu {
      margin: auto;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-menu-submenu {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      font-size: 15px;
      font-weight: 600;
      border: none !important;
    }

    .ant-menu-overflowed-submenu {
      .ant-menu-submenu-title {
        padding: 0px !important;
      }
    }
  }
}

.ant-menu-submenu-popup {
  background: transparent !important;
  .ant-menu {
    border-radius: 0 0 20px 20px;
    margin-top: 20px;
    margin-left: -40px;
    .ant-menu-item {
      min-height: 50px;
    }
  }
}

.ant-drawer {
  .ant-menu {
    height: 100% !important;
    .ant-menu-item {
      justify-content: flex-start !important;
      align-items: center !important;
      padding-left: 0 !important;
      min-height: 70px;
      display: flex;
    }
    .ant-menu-submenu {
      min-height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: center !important;

      .ant-menu-item {
        padding-left: 24px !important;
      }
    }

    .ant-menu-submenu-title {
      justify-content: flex-start !important;
      padding-left: 0 !important;
      flex: 1;
      display: flex;
      align-items: center;
    }
  }

  .ant-menu-inline {
    border-right: 0 !important;
  }

  .anticon {
    font-size: 20px !important;
    margin: 0 15px !important;
  }
  .ant-menu-submenu-title {
    display: flex;
    justify-content: center;
  }
}
.anticon-menu {
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 15px;
  font-size: 20px;
}
.social-media {
  display: none;
  @include tablet {
    display: flex;
    width: 100px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    .sites {
      padding: 0 8px;
    }
  }
}
