@import "../../styles/breakpoints.scss";

.cookieWrapper {
  @include animateEnterCookie();

  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  backdrop-filter: blur(100px);
  background: #ffffffba;
  box-shadow: 0px 0px 20px 0px #e2e2e2;
  font-size: small;
  padding: 20px;
  z-index: 1000;

  .buttonsWrapper {
    display: block;

    @include tablet {
      display: inline;
    }
  }

  .ant-btn {
    margin-left: 5px;
    margin-bottom: 5px;
    width: 85px;

    @include tablet {
      margin-left: 10px;
      width: 100px;
    }
  }
}
