@import "../../styles/breakpoints.scss";

.tab {
  display: inline;
  flex-direction: row;
  justify-content: flex-start;
  > a {
    text-decoration: none;
    color: black;
    font-size: 15px;
    font-weight: 600;
    @include tablet {
      font-size: 20px;
    }
  }
}
