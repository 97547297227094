@import "../../styles/breakpoints.scss";

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;

  .first-row {
    height: 50px;
    padding: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .anticon {
      font-size: 40px;
      margin: 0 1rem;
      color: #24a77c;
    }

    .ant-divider-vertical {
      height: 3rem;
      border: 1px solid #dadada;
    }
  }

  .second-row {
    height: 150px;
    padding: 3rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    .ant-divider-vertical {
      height: 3rem;
      border: 1px solid #dadada;
    }

    .copy-right {
      line-height: 2rem;
      width: 100%;
      @include tablet {
        width: initial;
      }
      a {
        display: inline-block;
        h4 {
          color: #1da57a;
        }
      }
    }

    h4 {
      font-weight: bold;
      margin: 0;
      display: block;
      @include tablet {
        display: inline;
      }
    }

    .builder {
      width: 50%;
      // z-index: 999;
      @include tablet {
        width: initial;
      }
      h4 {
        font-weight: bold;
        font-size: 14px;
        margin: 0;

        &.title {
          padding-bottom: 0.2rem;
          border-bottom: 2px solid #1da57a;
          color: #1da57a;
          display: inline;
          margin-left: 0.2rem;
        }
      }
    }
  }
}
